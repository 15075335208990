<template>
  <!-- 简历筛选角 -->
  <div class="sFooter">
    <div class="left">
      <span @click="changeStatus">更改状态</span>
      <van-icon name="arrow-down" />
    </div>
    <div class="mid" @click="toElimination">淘汰</div>
    <div class="right">
      <span @click="more">更多</span>
      <van-icon name="arrow-down" />
    </div>
    <van-popup v-model="show" position="bottom" get-container="body">
      <van-picker
        show-toolbar
        title="标题"
        :columns="columns"
        visible-item-count="5"
        @confirm="confirm"
        @cancel="cancel"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      columns: [
        {
          text: "初筛",
          children: [
            {
              text: "待初筛",
              children: [
                { text: "" },
                { text: "" },
                { text: "" },
                { text: "" },
              ],
            },
            {
              text: "初筛通过",
              children: [
                { text: "" },
                { text: "" },
                { text: "" },
                { text: "" },
              ],
            },
            {
              text: "初筛淘汰",
              children: [
                { text: "" },
                { text: "" },
                { text: "" },
                { text: "" },
              ],
            },
          ],
        },
        {
          text: "面试",
          children: [
            {
              text: "第一轮",
              children: [
                { text: "已安排面试" },
                { text: "已面试" },
                { text: "面试通过" },
                { text: "面试淘汰" },
              ],
            },
            {
              text: "第二轮",
              children: [
                { text: "已安排面试" },
                { text: "已面试" },
                { text: "面试通过" },
                { text: "面试淘汰" },
              ],
            },
            {
              text: "第三轮",
              children: [
                { text: "已安排面试" },
                { text: "已面试" },
                { text: "面试通过" },
                { text: "面试淘汰" },
              ],
            },
          ],
        },
        {
          text: "录用",
          children: [
            {
              text: "拟录用",
              children: [
                { text: "" },
                { text: "" },
                { text: "" },
                { text: "" },
              ],
            },
            {
              text: "已发Offer",
              children: [
                { text: "" },
                { text: "" },
                { text: "" },
                { text: "" },
              ],
            },
            {
              text: "已入职",
              children: [
                { text: "" },
                { text: "" },
                { text: "" },
                { text: "" },
              ],
            },
            {
              text: "放弃录用",
              children: [
                { text: "" },
                { text: "" },
                { text: "" },
                { text: "" },
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    changeStatus() {
      this.columns = [
        {
          text: "初筛",
          children: [
            {
              text: "待初筛",
              children: [
                { text: "" },
                { text: "" },
                { text: "" },
                { text: "" },
              ],
            },
            {
              text: "初筛通过",
              children: [
                { text: "" },
                { text: "" },
                { text: "" },
                { text: "" },
              ],
            },
            {
              text: "初筛淘汰",
              children: [
                { text: "" },
                { text: "" },
                { text: "" },
                { text: "" },
              ],
            },
          ],
        },
          {
          text: "面试",
          children: [
            {
              text: "第一轮",
              children: [
                { text: "已安排面试" },
                { text: "已面试" },
                { text: "面试通过" },
                { text: "面试淘汰" },
              ],
            },
            {
              text: "第二轮",
              children: [
                { text: "已安排面试" },
                { text: "已面试" },
                { text: "面试通过" },
                { text: "面试淘汰" },
              ],
            },
            {
              text: "第三轮",
              children: [
                { text: "已安排面试" },
                { text: "已面试" },
                { text: "面试通过" },
                { text: "面试淘汰" },
              ],
            },
          ],
        },
        {
          text: "录用",
          children: [
            {
              text: "拟录用",
              children: [
                { text: "" },
                { text: "" },
                { text: "" },
                { text: "" },
              ],
            },
            {
              text: "已发Offer",
              children: [
                { text: "" },
                { text: "" },
                { text: "" },
                { text: "" },
              ],
            },
            {
              text: "已入职",
              children: [
                { text: "" },
                { text: "" },
                { text: "" },
                { text: "" },
              ],
            },
            {
              text: "放弃录用",
              children: [
                { text: "" },
                { text: "" },
                { text: "" },
                { text: "" },
              ],
            },
          ],
        },
      ];
      this.show = true;
    },
    confirm() {
      this.show = false;
    },
    cancel() {
      this.show = false;
    },
    // 跳转淘汰页面
    toElimination() {
      this.$router.push({
        path: "/viewElimination",
        query: { title: "淘汰" },
      });
    },
    more() {
      this.columns = [{ text: "安排面试" }, { text: "发送Offer" }];
      this.show = true;
    },
  },
 
};
</script>

<style lang="less" scoped>
.sFooter {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
  // padding: 20px;
  box-sizing: border-box;
  background: #fff;
  font-size: 36px;
  color: #2b8df0;
  .left,
  .right,
  .mid {
    flex: 1;
    box-sizing: border-box;
    text-align: center;
    .van-icon {
      vertical-align: text-bottom;
      margin-left: 24px;
    }
  }
  .mid {
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
  }
}
</style>
