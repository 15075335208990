<template>
  <div class="offerFooter">
    <!-- <span @click="sendOffer">发送Offer</span> -->
    <van-button type="info"
                @click="sendOffer">发送Offer</van-button>
    <!-- <span @click="zitoElimination">淘汰</span> -->
  </div>
</template>

<script>
export default {
  methods: {
    sendOffer () {
      this.$emit('sendOffer')
    },
    // zitoElimination () {
    //   this.$emit('toElimination')
    // }
  }
};
</script>

<style lang="less" scoped>
.offerFooter {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  justify-content: center;
  .van-button {
    width: 80%;
    border-radius: 10px;
    .van-button__text {
      font-size: 28px;
      font-family: Source Han Sans CN;
    }
  }
  // span {
  //   display: inline-block;
  //   width: 50%;
  //   height: 100%;
  //   line-height: 80px;
  //   box-sizing: border-box;
  //   font-size: 36px;
  //   font-family: Source Han Sans CN;
  //   font-weight: 500;
  //   color: #2b8df0;
  //   text-align: center;
  // }
  // span:first-child {
  //   border-right: 1px solid #ccc;
  // }
}
</style>
