<template>
  <div class="arrangeFooter">
    <span @click="arrangeView">安排面试</span>
    <span @click="zitoElimination">淘汰</span>
  </div>
</template>

<script>
export default {
  methods: {
    arrangeView () {
      this.$emit('arrangeView')
    },
    zitoElimination () {
      this.$emit('toElimination')
    }
  }
};
</script>

<style lang="less" scoped>
.arrangeFooter {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  span {
    display: inline-block;
    width: 50%;
    height: 100%;
    line-height: 80px;
    box-sizing: border-box;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #2b8df0;
    text-align: center;
  }
  span:first-child {
    border-right: 1px solid #ccc;
  }
}
</style>
